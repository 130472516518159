import { Injectable } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Club } from '@models/clubs';
import { COLLECTIONS } from '@models/collections';
import { Movement, PlayerInscriptionRequest, PlayerInscriptionResponse } from '@models/inscription';
import { LicenseType, LicenseUserRate } from '@models/license';
import { Team } from '@models/teams';
import { User, UserRegistrationFindRequest, UserType } from '@models/user';
import { Optional } from '@models/utils';
import { FirestoreService } from '@services/firestore.service';
import { Observable, combineLatest, firstValueFrom, map, of, shareReplay, switchMap } from 'rxjs';
import { ClubsService } from './club.service';
import { PaymentService } from './payment.service';
import { SeasonManagerService } from './season-manager.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private COLLECTION = COLLECTIONS.USERS;

  public users$ = this.firestore.listDocuments<User>(this.COLLECTION).pipe(shareReplay(1));

  public all$: Observable<(User & { club?: Club })[]> = combineLatest([this.users$, this.clubsService.all$]).pipe(
    map(([users, clubs]) => {
      return users.map((user) => ({
        ...user,
        club: user.clubId ? clubs.find((club) => club.id === user.clubId) : undefined,
      }));
    }),
    shareReplay(1),
  );

  public licenses$ = this.seasonManager.season$.pipe(
    switchMap((seasonYear) =>
      this.firestore.listDocumentsGroup<LicenseUserRate>(
        COLLECTIONS.USERS_LICENSES,
        where('seasonYear', '==', seasonYear),
      ),
    ),
    shareReplay(1),
  );

  public allWithLicenses$: Observable<(User & { club?: Club; licenses?: LicenseUserRate[] })[]> = combineLatest([
    this.all$,
    this.licenses$,
  ]).pipe(
    map(([users, licenses]) => {
      return users.map((user) => ({
        ...user,
        licenses: licenses.filter((license) => license.userId === user.id),
      }));
    }),
    shareReplay(1),
  );

  public allUsersNotifications$: Observable<
    (User & {
      club?: Club;
      isClubManager: boolean;
      isSchoolManager: boolean;
      isRefereeCoordinator: boolean;
      licenseTypes: LicenseType[];
    })[]
  > = combineLatest([this.all$, this.licenses$]).pipe(
    map(([users, licenses]) => {
      return users
        .filter((usr) => usr.fcmToken)
        .map((user) => {
          const club = user.club;
          const isClubManager = user.club?.managerId === user.id;
          const isSchoolManager = user.club?.schoolManagerId === user.id;

          // Verificar si es coordinator
          const isRefereeCoordinator = user.types?.includes(UserType.REFEREE_COORDINATOR) ?? false;
          const licenseTypes: LicenseType[] = [];
          licenses
            .filter((license) => license.userId === user.id)
            .map((license2) => licenseTypes.push(license2.licenseType));
          return {
            ...user,
            club,
            isClubManager,
            isSchoolManager,
            isRefereeCoordinator,
            licenseTypes,
          };
        });
    }),
    shareReplay(1),
  );

  constructor(
    private firestore: FirestoreService,
    private clubsService: ClubsService,
    private functions: Functions,
    private seasonManager: SeasonManagerService,
    private paymentService: PaymentService,
  ) {}

  get(id: string) {
    return this.firestore.getDocument<User>(this.COLLECTION, id);
  }

  getReferees() {
    return this.seasonManager.season$.pipe(
      switchMap((seasonYear) => {
        console.log('Season Year:', seasonYear);
        return this.firestore.listDocumentsGroup<LicenseUserRate>(
          COLLECTIONS.USERS_LICENSES,
          where('seasonYear', '==', seasonYear),
          where('licenseType', '==', 'REFEREE'),
        );
      }),
      switchMap((licenses) => {
        const userIds = licenses.map((license) => license.userId);

        if (userIds.length === 0) {
          return of([]);
        }

        const chunkArray = (array: string[], size: number) => {
          const result = [];
          for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, size + i));
          }
          return result;
        };

        const chunks = chunkArray(userIds, 30);

        const observables = chunks.map((chunk) => {
          return this.firestore.listDocuments<User>(COLLECTIONS.USERS, where('id', 'in', chunk));
        });

        return combineLatest(observables).pipe(
          map((results) => {
            return results.flat();
          }),
        );
      }),
      shareReplay(1),
    );
  }

  getPlayers(clubId: string): Observable<User[]> {
    return this.firestore.listDocuments<Team>(COLLECTIONS.TEAMS, where('clubId', '==', clubId)).pipe(
      map((teams) => {
        const playerIds = [...new Set(teams.flatMap((team) => team.playersIds || []))];
        return playerIds;
      }),
      switchMap((playerIds) => {
        if (playerIds.length === 0) {
          return of([]);
        }
        return combineLatest(playerIds.map((playerId) => this.firestore.getDocument<User>(this.COLLECTION, playerId)));
      }),
    );
  }

  getDelegates(clubId: string): Observable<User[]> {
    return this.firestore.listDocuments<Team>(COLLECTIONS.TEAMS, where('clubId', '==', clubId)).pipe(
      map((teams) => {
        const delegatesIds = [...new Set(teams.flatMap((team) => team.delegatesIds || []))];
        return delegatesIds;
      }),
      switchMap((delegatesIds) => {
        if (delegatesIds.length === 0) {
          return of([]);
        }
        return combineLatest(
          delegatesIds.map((delegateId) => this.firestore.getDocument<User>(this.COLLECTION, delegateId)),
        );
      }),
    );
  }

  getReport() {
    return this.firestore.listDocuments<User>(this.COLLECTION);
  }

  getUsersByClubId(clubId: string): Observable<(User & { club?: Club })[]> {
    return this.all$.pipe(map((users) => users.filter((user) => user.clubId === clubId)));
  }

  findPlayer(data: PlayerInscriptionRequest) {
    const callable = httpsCallable<PlayerInscriptionRequest, PlayerInscriptionResponse>(this.functions, 'findPlayer');
    return callable(data);
  }

  createOrUpdate(user: Optional<User, 'id'>) {
    user.document = user.document.toUpperCase();
    return user.id ? this.update(user.id, user) : this.createUser(user);
  }

  private createUser(user: Omit<User, 'id'>) {
    return this.firestore.create(this.COLLECTION, user);
  }

  public update(id: string, user: Partial<User>) {
    return this.firestore.update(this.COLLECTION, {
      id,
      ...user,
    });
  }

  createUserFromAdminPanel(data: Omit<User, 'id'>) {
    const callable = httpsCallable<Omit<User, 'id'>, string>(this.functions, 'createUserFromAdminPanel');
    return callable(data);
  }

  checkIfUserIsRegistered(data: UserRegistrationFindRequest) {
    const callable = httpsCallable<UserRegistrationFindRequest, Partial<User & { redirect: boolean }>>(
      this.functions,
      'checkIfUserIsRegistered',
    );
    return callable(data);
  }

  async createUserAndAuth(user: Omit<User, 'types'> | Partial<User>) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const callable = httpsCallable<any, { uid: string }>(this.functions, 'createUserAndAuth');
    return callable(user);
  }

  checkIfDuplicatedDocument(document: string) {
    return firstValueFrom(
      this.firestore.findDocument<User>(this.COLLECTION, where('document', '==', document.toUpperCase())),
    );
  }

  checkIfDuplicatedPhone(phone: string) {
    return firstValueFrom(this.firestore.findDocument<User>(this.COLLECTION, where('phone', '==', phone)));
  }

  checkIfDuplicatedEmail(email: string) {
    return firstValueFrom(this.firestore.findDocument<User>(this.COLLECTION, where('email', '==', email)));
  }

  getUserLicenses(userId: string) {
    return this.firestore.listDocumentsGroup<LicenseUserRate>(
      COLLECTIONS.USERS_LICENSES,
      where('userId', '==', userId),
      orderBy('seasonYear', 'desc'),
    );
  }

  async deleteUserLicenseAndModifyMovement(license: LicenseUserRate) {
    await this.firestore.deleteForever(
      [COLLECTIONS.USERS, license.userId, COLLECTIONS.USERS_LICENSES].join('/'),
      license.id,
    );
    const clubMovements = await firstValueFrom(this.paymentService.getByClub(license.clubId));
    let relatedMovement: Movement | undefined;

    if (license.licenseType === LicenseType.PLAYER) {
      relatedMovement = clubMovements.find((movement) =>
        movement.players?.some((player) => player.id === license.userId && player.price === license.price),
      );

      if (relatedMovement) {
        relatedMovement.players = relatedMovement.players?.filter((player) => player.id !== license.userId);

        relatedMovement.amount -= license.price;
      }
    } else if (license.licenseType === LicenseType.DELEGATE) {
      relatedMovement = clubMovements.find((movement) =>
        movement.delegates?.some((delegate) => delegate.id === license.userId && delegate.price === delegate.price),
      );

      if (relatedMovement) {
        relatedMovement.delegates = relatedMovement.delegates?.filter((delegate) => delegate.id !== license.userId);

        relatedMovement.amount -= license.price;
      }
    }
    if (relatedMovement) {
      await this.paymentService.update(relatedMovement.id, relatedMovement);
    }
  }

  resetEveryUser() {
    const callable = httpsCallable(this.functions, 'onResetUsers');
    return callable();
  }
}
