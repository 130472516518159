import { Injectable } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { Category } from '@models/masters';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public getCategory(birthDate: Date): Category {
    const age = this.calculateAge(birthDate);

    if (age >= 50) {
      return Category.MASTER50;
    } else if (age >= 45) {
      return Category.MASTER;
    } else if (age >= 18) {
      return Category.SENIOR;
    } else if (age >= 16) {
      return Category.JUVENIL;
    } else if (age >= 14) {
      return Category.CADETE;
    } else if (age >= 12) {
      return Category.INFANTIL;
    } else if (age >= 10) {
      return Category.ALEVIN;
    } else {
      return Category.BENJAMIN;
    }
  }

  private calculateAge(birthDate: Date): number {
    let birthDateDate = birthDate;
    if (birthDate instanceof Timestamp) {
      birthDateDate = birthDate.toDate();
    }
    const today = new Date();
    const birthYear = birthDateDate.getFullYear();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    let age = currentYear - birthYear;

    if (currentMonth < 7) {
      age--;
    }

    return age;
  }

  convertImageUrlToBlob(dataURL: string): Blob {
    const byteString = atob(dataURL.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([uint8Array], { type: 'image/png' });
  }
}
