import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { COLLECTIONS } from '@models/collections';
import { Season, SeasonLeague } from '@models/competitions';
import { LicenseRateSeason, LicenseType } from '@models/license';
import { CompetitionType, Scope } from '@models/masters';
import { UserGender } from '@models/user';
import { addDays } from 'date-fns';
import { Observable, catchError, firstValueFrom, forkJoin, from, map, of, shareReplay, switchMap, take } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { SeasonManagerService } from './season-manager.service';
import { UsersService } from './users.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class SeasonsService {
  private COLLECTION = COLLECTIONS.SEASON;

  public all$ = this.seasonManager.season$.pipe(
    switchMap((season) => this.firestore.listDocuments<Season>(this.COLLECTION, where('seasonYear', '==', season))),
    shareReplay(1),
  );

  public seasonsWithActivatedTeamInscription$ = this.all$.pipe(
    map((seasons: Season[]) => {
      const now = new Date();
      return seasons.filter((season) => {
        if (!season.inscriptionTeamsStartDate || !season.inscriptionTeamsEndDate) {
          return false;
        }
        const startDate = season.inscriptionTeamsStartDate;
        const endDate = addDays(season.inscriptionTeamsEndDate, 1);
        return startDate && endDate && startDate <= now && endDate >= now;
      });
    }),
  );

  constructor(
    private firestore: FirestoreService,
    private seasonManager: SeasonManagerService,
    private utilsService: UtilsService,
    private usersService: UsersService,
  ) {}

  get(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.all$.pipe(map((season) => season.find((c) => c.id === id)!));
  }

  getLeagues(scope: Scope) {
    return this.all$.pipe(
      map((seasons) => seasons.filter((s) => s.type === CompetitionType.LEAGUE && s.scope === scope)),
    );
  }

  getLeague(docId: string) {
    return this.firestore.getDocument<SeasonLeague>(this.COLLECTION, docId);
  }

  create(season: Partial<Season>) {
    return this.firestore.create<Partial<Season>>(this.COLLECTION, {
      ...season,
      seasonYear: this.seasonManager.getSeason(),
    });
  }

  update(id: string, season: Partial<Season>) {
    return this.firestore
      .update<Season>(this.COLLECTION, {
        id,
        ...season,
      } as Season)
      .then(() => id);
  }

  createOrUpdate(id: string | undefined, season: Partial<Season>) {
    return id ? this.update(id, season) : this.create(season);
  }

  saveRates(seasonId: string, rates: LicenseRateSeason) {
    return this.firestore
      .createOrUpdate([COLLECTIONS.SEASON, seasonId, COLLECTIONS.SEASON_RATES].join('/'), seasonId, {
        id: seasonId,
        teamRates: rates.teamRates,
        licenseRates: rates.licenseRates,
      })
      .then(() => seasonId);
  }

  getRates(seasonId: string) {
    return this.firestore
      .getDocument<LicenseRateSeason>([COLLECTIONS.SEASON, seasonId, COLLECTIONS.SEASON_RATES].join('/'), seasonId)
      .pipe(shareReplay(1));
  }

  async findLicenseRateForAPlayer(seasonId: string, birthDate: Date, gender: UserGender, licenseType: LicenseType) {
    try {
      const rates = await firstValueFrom(this.getRates(seasonId));
      if (!rates.licenseRates) {
        throw new Error('No hay tarifas definidas para la temporada');
      }
      const category = this.utilsService.getCategory(birthDate);
      return rates.licenseRates
        .filter((data) => data.licenseType === licenseType)
        .filter((data) => data.genders.includes(gender))
        .filter((data) => data.categories.includes(category));
    } catch (error) {
      throw new Error('No hay tarifas definidas para la temporada');
    }
  }

  getReport() {
    return this.firestore.listDocuments<Season>(this.COLLECTION);
  }

  getSeasonWithEnrichedPlayerRankings(seasonId: string): Observable<Season> {
    return this.get(seasonId).pipe(
      switchMap((season) => {
        if (!season.hasPlayerRanking || !(season as SeasonLeague).playersRanking) {
          console.log('No player rankings found in season');
          return from([season]);
        }

        const playerRankings = (season as SeasonLeague).playersRanking || [];

        if (playerRankings.length === 0) {
          console.log('Player rankings array is empty');
          return from([season]);
        }

        console.log(`Processing ${playerRankings.length} player rankings`);

        const playerRequests = playerRankings.map((ranking) =>
          this.usersService.get(ranking.playerId).pipe(
            take(1),
            map((userData) => {
              console.log(`Got user data for player ${ranking.playerId}`);
              return { ...ranking, userData };
            }),
            catchError((error) => {
              console.error(`Failed to load user data for player ${ranking.playerId}:`, error);
              return of({ ...ranking, userData: null });
            }),
          ),
        );

        if (playerRequests.length === 0) {
          console.log('No player requests generated');
          return from([season]);
        }

        return forkJoin(playerRequests).pipe(
          map((enrichedRankings) => {
            console.log(`Successfully enriched ${enrichedRankings.length} player rankings`);
            return {
              ...season,
              playersRanking: enrichedRankings,
            } as Season;
          }),
          catchError((error) => {
            console.error('Error enriching player rankings:', error);
            return from([season]);
          }),
        );
      }),
    );
  }
}
